import { useState, ChangeEvent } from "react";
import {
  Box,
  Text,
  Input,
  Button,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useToast,
  InputGroup,
  InputRightElement,
  HStack,
  IconButton,
  Select,
} from "@chakra-ui/react";
import { IRegisterUser } from "../types/register/interface";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router";
import { useAuth } from "../contexts/authContext";
import { TermosUso } from "../components/termos";
import FloatingWhatsAppButton from "../components/floatingWhatsAppButton";

export const Register = () => {
  const [formData, setFormData] = useState<IRegisterUser>({
    nome: "",
    senha: "",
    telefone: "",
    email: "",
    cpf: "",
    perfil: 2,
    endereco: {
      logradouro: "null",
      numero: "null",
      complemento: "null",
      bairro: "null",
      cep: "null",
      cidade: "",
      estado: "",
    },
  });
  const [showSenha, setShowSenha] = useState(false);
  const toast = useToast();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [outsideCheckboxAccepted, setOutsideCheckboxAccepted] = useState(false);
  const [modalCheckboxAccepted, setModalCheckboxAccepted] = useState(false);
  const [formattedCPF, setFormattedCPF] = useState("");
  const [formattedTelefone, setFormattedTelefone] = useState("");
  const navigate = useNavigate();
  const { signUp } = useAuth();
  const handleClickShowSenha = () => setShowSenha(!showSenha);

  function validaCPF(cpf: string): boolean {
    cpf = cpf.replace(/\D/g, "");
    if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) {
      return false;
    }

    let soma = 0;
    let resto: number;
    for (let i = 1; i <= 9; i++) {
      soma += parseInt(cpf[i - 1]) * (11 - i);
    }

    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(cpf[9])) {
      return false;
    }

    soma = 0;
    for (let i = 1; i <= 10; i++) {
      soma += parseInt(cpf[i - 1]) * (12 - i);
    }
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(cpf[10])) {
      return false;
    }

    return true;
  }

  const estadosBrasileiros = [
    { label: "AC" },
    { label: "AL" },
    { label: "AP" },
    { label: "AM" },
    { label: "BA" },
    { label: "CE" },
    { label: "DF" },
    { label: "ES" },
    { label: "GO" },
    { label: "MA" },
    { label: "MT" },
    { label: "MS" },
    { label: "MG" },
    { label: "PA" },
    { label: "PB" },
    { label: "PR" },
    { label: "PE" },
    { label: "PI" },
    { label: "RJ" },
    { label: "RN" },
    { label: "RS" },
    { label: "RO" },
    { label: "RR" },
    { label: "SC" },
    { label: "SP" },
    { label: "SE" },
    { label: "TO" },
  ];

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "cpf") {
      const cleanedValue = value.replace(/\D/g, "").slice(0, 11);
      const formattedValue = cleanedValue.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        "$1.$2.$3-$4"
      );

      setFormattedCPF(formattedValue);
      setFormData({ ...formData, cpf: cleanedValue });
    } else if (name === "telefone") {
      const cleanedValue = value.replace(/\D/g, "");
      const formattedValue = cleanedValue
        .slice(0, 11)
        .replace(/(\d{2})(\d{5})(\d{4})/, "($1)$2-$3");

      setFormattedTelefone(formattedValue);
      setFormData({ ...formData, telefone: cleanedValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOutsideCheckboxAccepted(e.target.checked);
  };

  const handleModalCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setModalCheckboxAccepted(e.target.checked);
  };

  const handleOpenTermsModal = () => {
    setShowTermsModal(true);
  };

  const handleCloseTermsModal = () => {
    setShowTermsModal(false);
  };

  const handleSaveTerms = async () => {
    if (modalCheckboxAccepted) {
      setTermsAccepted(true);
      setOutsideCheckboxAccepted(true);
    } else {
      setTermsAccepted(false);
      setOutsideCheckboxAccepted(false);
    }
    setShowTermsModal(false);
  };

  const handleSubmit = async () => {
    const { nome, senha, email, telefone, cpf, endereco } = formData;

    if (
      !nome ||
      !senha ||
      !email ||
      !telefone ||
      !cpf ||
      !endereco.cidade ||
      !endereco.estado
    ) {
      toast({
        title: "Por favor, preencha todos os campos.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    if (!validaCPF(cpf)) {
      toast({
        title: "CPF inválido",
        description: "Por favor, insira um CPF válido.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!termsAccepted && !modalCheckboxAccepted) {
      setShowTermsModal(true);
      return;
    }

    try {
      const success = await signUp(formData);

      if (success) {
        setShowSuccessModal(true);
        setFormData({
          nome: "",
          email: "",
          senha: "",
          telefone: "",
          cpf: "",
          perfil: 2,
          endereco: {
            logradouro: "",
            numero: "",
            complemento: "",
            bairro: "",
            cep: "",
            cidade: "",
            estado: "",
          },
        });

        setFormattedCPF("");
        setFormattedTelefone("");
        setTermsAccepted(false);
        setModalCheckboxAccepted(false);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.erros?.join(", ") || "Erro ao tentar registrar.";
      toast({
        title: "Erro",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSenhaChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFormData({ ...formData, senha: value });
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    navigate("/sign-in");
  };

  const termsLinkStyles = {
    textDecoration: termsAccepted ? "none" : "underline",
    color: termsAccepted ? "gray.500" : "blue.500",
    cursor: "pointer",
  };

  return (
    <Box
      bg="#E5E7EB"
      borderRadius="md"
      boxShadow="md"
      maxW={{ base: "90%", md: "md" }}
      mx="auto"
      mt={{ base: 10, md: 7 }}
      p={{ base: 3, md: 5 }}
      w="100%"
      marginTop="6%"
      marginBottom="6%"
    >
      <>
        <Text
          fontSize={{ base: "2xl", md: "3xl" }}
          fontWeight="bold"
          mb={4}
          textAlign="center"
        >
          Cadastre-se
        </Text>
        <Box mb={4}>
          <Text mb={2}>Nome completo</Text>
          <Input
            borderColor="#333333"
            placeholder="Nome"
            name="nome"
            value={formData?.nome}
            onChange={handleChange}
          />
        </Box>
        <Box mb={4}>
          <Text mb={2}>CPF</Text>
          <Input
            borderColor="#333333"
            placeholder="CPF"
            name="cpf"
            value={formattedCPF}
            onChange={handleChange}
          />
        </Box>
        <Box mb={4}>
          <Text mb={2}>E-Mail</Text>
          <Input
            borderColor="#333333"
            placeholder="E-Mail"
            name="email"
            value={formData?.email}
            onChange={handleChange}
          />
        </Box>

        <Box mb={4}>
          <Text mb={2}>Telefone</Text>
          <Input
            borderColor="#333333"
            placeholder="(00)00000-0000"
            name="telefone"
            value={formattedTelefone}
            onChange={handleChange}
          />
        </Box>

        <HStack>
          <Box mb={4}>
            <Text mb={2}>Cidade</Text>
            <Input
              borderColor="#333333"
              placeholder="Cidade"
              name="cidade"
              value={formData.endereco.cidade}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  endereco: { ...formData.endereco, cidade: e.target.value },
                })
              }
            />
          </Box>

          <Box mb={4}>
            <Text mb={2}>Estado</Text>
            <Select
              borderColor="#333333"
              placeholder="Selecione o Estado"
              name="estado"
              value={formData.endereco.estado}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  endereco: { ...formData.endereco, estado: e.target.value },
                })
              }
            >
              {estadosBrasileiros.map((estado) => (
                <option key={estado.label} value={estado.label}>
                  {estado.label}
                </option>
              ))}
            </Select>
          </Box>
        </HStack>
        <HStack></HStack>
        <Box mb={4}>
          <Text mb={2}>Senha</Text>
          <InputGroup>
            <Input
              borderColor="#333333"
              placeholder="Senha"
              name="senha"
              value={formData.senha}
              onChange={handleSenhaChange}
              type={showSenha ? "text" : "password"}
            />
            <InputRightElement width="4.5rem">
              <IconButton
                h="1.75rem"
                size="sm"
                onClick={handleClickShowSenha}
                icon={showSenha ? <FaEyeSlash /> : <FaEye />}
                aria-label={showSenha ? "Ocultar senha" : "Mostrar senha"}
              />
            </InputRightElement>
          </InputGroup>
        </Box>
        <Button
          mt={3}
          colorScheme="green"
          w="100%"
          bg="#16A34A"
          onClick={handleSubmit}
        >
          Registrar
        </Button>
        <Checkbox
          colorScheme="green"
          borderColor="gray.400"
          _hover={{ borderColor: "gray.500" }}
          mt={4}
          isChecked={outsideCheckboxAccepted}
          onChange={handleCheckboxChange}
        >
          Aceito os{" "}
          <Text
            as="span"
            onClick={handleOpenTermsModal}
            style={termsLinkStyles}
          >
            Termos de Uso
          </Text>
        </Checkbox>
      </>
      <Modal isOpen={showTermsModal} onClose={handleCloseTermsModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Termos de Uso</ModalHeader>
          <ModalBody>
            <TermosUso />
            <Checkbox
              mt={4}
              isChecked={modalCheckboxAccepted}
              onChange={handleModalCheckboxChange}
            >
              Eu li e aceito os Termos de Uso
            </Checkbox>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" onClick={handleSaveTerms}>
              Salvar
            </Button>
            <Button ml={3} onClick={handleCloseTermsModal}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={showSuccessModal} onClose={handleCloseSuccessModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Registro bem-sucedido</ModalHeader>
          <ModalBody>
            <Text>Seu registro foi realizado com sucesso!</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" onClick={handleCloseSuccessModal}>
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>{" "}
      <FloatingWhatsAppButton />
    </Box>
  );
};
