import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { jwtDecode } from "jwt-decode";
import { UserRepository } from "../domain/repositories/user";
import { IRegisterUser, ISignIn } from "../types/register/interface";
import { useNavigate } from "react-router-dom";

interface AuthContextType {
  user: ISignIn | null;
  isAuthenticated: boolean;
  token: string | null;
  signIn: (cpf: string, senha: string) => Promise<void>;
  signUp: (userData: IRegisterUser) => Promise<boolean>;
  signOut: () => void;
  updateLastAccess: () => void;
  isAdmin: () => boolean;
  isCliente: () => boolean;
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  isAuthenticated: false,
  token: null,
  signIn: async () => {},
  signUp: async () => false,
  signOut: () => {},
  updateLastAccess: () => {},
  isAdmin: () => false,
  isCliente: () => false,
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<ISignIn | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(null);
  const navigate = useNavigate();
  let inactivityTimeout: NodeJS.Timeout;
  const isAdmin = () => user?.perfil?.idPerfil === 1;
  const isCliente = () => user?.perfil?.idPerfil === 2;

  const updateLastAccess = () => {
    localStorage.setItem("lastAccess", Date.now().toString());
    resetInactivityTimeout();
  };

  const resetInactivityTimeout = () => {
    clearTimeout(inactivityTimeout);
    inactivityTimeout = setTimeout(() => {
      signOut();
    }, 600000); // 10 minutos
  };

  const signIn = async (cpf: string, senha: string): Promise<void> => {
    try {
      const response = await UserRepository.signIn(cpf, senha);

      if (response && response.token) {
        const decodedToken: any = jwtDecode(response.token);

        const userData = await UserRepository.getUserByCpf(
          decodedToken.sub,
          response.token
        );

        setUser(userData);
        setToken(response.token);
        setIsAuthenticated(true);

        // Troca de localStorage para sessionStorage
        sessionStorage.setItem("user", JSON.stringify(userData));
        sessionStorage.setItem("token", response.token);
      } else {
        throw new Error("Resposta inesperada do servidor.");
      }
    } catch (error) {
      console.error("Erro ao tentar Logar:", error);
      throw error;
    }
  };

  const signUp = async (userData: IRegisterUser): Promise<boolean> => {
    try {
      const response = await UserRepository.signUp(userData);

      if (response && response.conteudo && response.conteudo.token) {
        const decodedToken: any = jwtDecode(response.conteudo.token);
        const userData = await UserRepository.getUserByCpf(
          decodedToken.sub,
          response.conteudo.token
        );

        setUser(userData);
        setToken(response.conteudo.token);
        setIsAuthenticated(true);
        localStorage.setItem("user", JSON.stringify(userData));
        localStorage.setItem("token", response.conteudo.token);
        return true;
      } else {
        throw new Error(
          response.erros ? response.erros[0] : "Erro desconhecido"
        );
      }
    } catch (error) {
      console.error("Erro ao tentar registrar:", error);
      throw error;
    }
  };

  useEffect(() => {
    const storedUser = sessionStorage.getItem("user");
    const storedToken = sessionStorage.getItem("token");

    if (storedUser && storedToken) {
      const parsedUser: ISignIn = JSON.parse(storedUser);
      setUser(parsedUser);
      setToken(storedToken);
      setIsAuthenticated(true);
      resetInactivityTimeout();
    }
  }, []);

  const signOut = () => {
    clearTimeout(inactivityTimeout);
    // Limpa sessionStorage ao deslogar
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("token");
    setUser(null);
    setToken(null);
    setIsAuthenticated(false);
    navigate("/sign-in");
  };

  useEffect(() => {
    window.addEventListener("click", updateLastAccess);
    window.addEventListener("keydown", updateLastAccess);

    return () => {
      window.removeEventListener("click", updateLastAccess);
      window.removeEventListener("keydown", updateLastAccess);
      clearTimeout(inactivityTimeout);
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        token,
        signIn,
        signUp,
        signOut,
        updateLastAccess,
        isAdmin,
        isCliente,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
