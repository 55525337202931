import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Stack,
  IconButton,
  Flex,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { http } from "../../services/http";
import { useAuth } from "../../contexts/authContext";

type Bet = {
  cotasId: number;
  numerosCota: { numero: number; numeroAcertado: boolean }[];
};

type BetData = {
  dataCompra: string;
  valorCompra: number;
  palpitesNumeros: Bet[];
};

type UserData = {
  nome: string;
  cidade: string;
  telefone: string;
  userId: number;
  salaId: number;
  valorTotalCompras: number;
};
const formatNumber = (number: number): string => {
  return number.toString().padStart(4, "0");
};
const formatNumbers = (numbers: Bet[]) => {
  if (!numbers) {
    return null;
  }

  return (
    <>
      {numbers.map((bet, index) => (
        <Box
          key={index}
          mb={2}
          display="flex"
          flexWrap="nowrap"
          overflowX="auto"
        >
          {bet.numerosCota.map((num, i) => (
            <Box
              key={i}
              bg="transparent"
              color="#098D1E"
              fontWeight="bold"
              border="2px solid green"
              borderRadius="50%"
              width="26px"
              height="26px"
              lineHeight="26px"
              marginRight="5px"
              display="inline-flex"
              justifyContent="center"
              alignItems="center"
            >
              {num.numero}
            </Box>
          ))}
        </Box>
      ))}
    </>
  );
};

const formatDate = (dateString: string) => {
  const [year, month, day] = dateString.split("-");
  return `${day}/${month}/${year}`;
};

const ViewShopping = () => {
  const [bets, setBets] = useState<BetData[]>([]);
  const location = useLocation();
  const { userId, salaId } = location.state || {};
  const { token } = useAuth();
  const [userData, setUserData] = useState<UserData | null>(null);

  useEffect(() => {
    const fetchBets = async () => {
      try {
        const response = await http.get(
          `/cotas/usuario/${userId}/sala/${salaId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const userData = response.data.conteudo;
        setUserData({
          nome: userData.nome,
          cidade: userData.cidade,
          telefone: userData.telefone,
          userId: userData.userId,
          salaId: userData.salaId,
          valorTotalCompras: userData.valorTotalCompras,
        });
        setBets(userData.palpitesSala || []);
      } catch (error) {
        console.error("Erro ao buscar palpites:", error);
      }
    };

    fetchBets();
  }, [userId, salaId, token]);

  const getPremio = (premio: number): string => {
    return premio.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  return (
    <Stack spacing={4} p="4" mx="auto" maxW="100%">
      <Box bg="white" borderRadius="5px" overflow="hidden" position="relative">
        <IconButton
          icon={<ArrowBackIcon />}
          aria-label="Voltar"
          position="absolute"
          top={2}
          left={2}
          colorScheme="blue"
          onClick={() => window.history.back()}
        />

        <Box textAlign="center" mb="4" mt={2} pt={2}>
          <Text fontWeight="bold">
            Usuário: {userData?.userId ? formatNumber(userData.userId) : "N/A"}
          </Text>
          <Text fontWeight="bold">Nome: {userData?.nome}</Text>
          <Text fontWeight="bold">Cidade: {userData?.cidade}</Text>
          <Text fontWeight="bold">Telefone: {userData?.telefone}</Text>
        </Box>
        <Text
          color="#068C1C"
          textAlign="center"
          fontSize="2xl"
          fontWeight="bold"
          mb={4}
        >
          Compras no Bolão
        </Text>
        <Box
          bg="#006391"
          textAlign="center"
          color="white"
          fontWeight="bold"
          mb={2}
        >
          <Text mb={2}>
            Bolão - {userData?.salaId ? formatNumber(userData.salaId) : "N/A"}
          </Text>
        </Box>

        <Box overflowX="auto" whiteSpace="nowrap">
          <Table variant="simple" mt="4" maxW="100%">
            <Thead>
              <Tr>
                <Th textAlign="center">Palpite</Th>
                <Th textAlign="center">Números</Th>
              </Tr>
            </Thead>
            <Tbody>
              {bets.map((bet, index) => (
                <React.Fragment key={index}>
                  <Tr>
                    <Td textAlign="center" color="#068C1C" fontWeight="bold">
                      {bet.palpitesNumeros.map((p, index) => (
                        <Box key={index} mb={3}>
                          {formatNumber(p.cotasId)}
                        </Box>
                      ))}
                    </Td>
                    <Td textAlign="center">
                      {formatNumbers(bet.palpitesNumeros)}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td colSpan={2} textAlign="center">
                      <Text fontWeight="bold" mt="4">
                        Data da Compra: {formatDate(bet.dataCompra)}
                      </Text>
                      <Text fontWeight="bold" mt="3" pb={5}>
                        Valor da Compra: {getPremio(bet.valorCompra)}
                      </Text>
                    </Td>
                  </Tr>
                </React.Fragment>
              ))}

              {bets.length === 0 && (
                <Tr>
                  <Td colSpan={2} textAlign="center">
                    Nenhum dado encontrado
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>{" "}
          <Flex justifyContent="flex-end" mt="1" mr={5} mb={5}>
            {userData?.valorTotalCompras !== undefined ? (
              <Text
                fontWeight="bold"
                color="#006391"
                textAlign="right"
                width="100%"
              >
                Total em compras:{" "}
                <Box
                  as="span"
                  bg="#006391"
                  color="white"
                  p={2}
                  borderRadius="md"
                  display="inline-block"
                >
                  {getPremio(userData.valorTotalCompras)}
                </Box>
              </Text>
            ) : (
              <Text
                fontWeight="bold"
                color="#006391"
                textAlign="right"
                width="100%"
              >
                Total em compras: N/A
              </Text>
            )}
          </Flex>
        </Box>
      </Box>
    </Stack>
  );
};

export default ViewShopping;
