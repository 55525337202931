import React, { useState } from "react";
import {
  Box,
  Text,
  Input,
  Button,
  useToast,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useAuth } from "../contexts/authContext";
import FloatingWhatsAppButton from "../components/floatingWhatsAppButton";

const SignIn = () => {
  const [cpf, setCpf] = useState("");
  const [senha, setSenha] = useState("");
  const toast = useToast();
  const [show, setShow] = React.useState(false);
  const { signIn } = useAuth();

  const formatCPF = (inputCPF: string) => {
    const cpfNumbers = inputCPF.replace(/\D/g, "");
    return cpfNumbers.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  };

  const handleCpfChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputCPF = e.target.value;
    const formattedCPF = inputCPF.replace(/\D/g, "").slice(0, 11);
    setCpf(formattedCPF);
  };

  const handleClick = () => setShow(!show);

  const handleSubmit = async () => {
    if (cpf.length !== 11) {
      toast({
        title: "CPF inválido",
        description: "O CPF deve ter 11 dígitos.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await signIn(cpf, senha);
      window.location.href = "/";
    } catch (error: any) {
      toast({
        title: "Erro de Login",
        description:
          error.response?.data?.erros?.join(", ") ||
          "Não foi possível fazer login. Tente novamente.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  return (
    <Box
      bg="#E5E7EB"
      borderRadius="md"
      boxShadow="md"
      maxW={{ base: "90%", md: "md" }}
      mx="auto"
      mt={{ base: 10, md: 20 }}
      p={{ base: 3, md: 5 }}
      w="100%"
      marginTop="6%"
      marginBottom="6%"
    >
      <Text
        fontSize={{ base: "2xl", md: "3xl" }}
        fontWeight="bold"
        mb={4}
        textAlign="center"
      >
        Login
      </Text>
      <Box mb={4}>
        <Text mb={2}>Insira seu CPF</Text>

        <Input
          borderColor="#333333"
          placeholder="CPF"
          value={formatCPF(cpf)}
          onChange={handleCpfChange}
        />
      </Box>
      <Box mb={4} position="relative">
        <Text mb={2}>Insira sua senha</Text>
        <InputGroup size="md">
          <Input
            name="senha"
            pr="4.5rem"
            type={show ? "text" : "password"}
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            borderColor="#333333"
            placeholder="Senha"
            border="1px solid black"
          />
          <InputRightElement width="4.5rem">
            <Button
              h="1.75rem"
              size="sm"
              onClick={handleClick}
              background="transparent"
              colorScheme="transparent"
              color="black"
            >
              {show ? <FaEyeSlash /> : <FaEye />}
            </Button>
          </InputRightElement>
        </InputGroup>
      </Box>
      <Link to="/register">
        <Text
        fontSize="lg"
          textAlign="center"
          mb={2}
          textDecoration="underline"
          fontWeight="bold"
        >
          Não tem uma conta? Registre-se
        </Text>
      </Link>
      <Link to="/forgotPassword">
        <Text textAlign="center" mb={2} textDecoration="underline">
          Esqueceu sua senha?
        </Text>
      </Link>
      <Box textAlign="center">
        <Button
          onClick={handleSubmit}
          colorScheme="green"
          mb={{ base: 5, md: 0 }}
          w="100%"
          bg="#16A34A"
        >
          Entrar
        </Button>
      </Box>{" "}
      <FloatingWhatsAppButton />
    </Box>
  );
};

export default SignIn;
