import { useState, useEffect, useRef } from "react";
import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Stack,
  Heading,
  Input,
  Select,
  Center,
  Text,
  Flex,
  CircularProgress,
  Container,
} from "@chakra-ui/react";
import "jspdf-autotable";
import { useAuth } from "../contexts/authContext";
import { http } from "../services/http";
import FloatingWhatsAppButton from "../components/floatingWhatsAppButton";
import { throttle } from "lodash";

interface NumeroCota {
  numero: number;
  numeroAcertado: boolean;
}

interface RankingInfo {
  diaConcurso: string;
  numeroConcurso: number;
  totalPontosConcurso: number;
  numerosCotas: NumeroCota[];
}

interface StatusSala {
  id: number;
  descricao: string;
}

interface Winner {
  salaId: number;
  rankingId: number;
  cotasId: number;
  ordem: number;
  nome: string;
  cidade: string;
  totalPontos: number;
  statusSala: StatusSala;
  cotasConcursosVencedores: RankingInfo[];
}

const Ranking = () => {
  const [winners, setWinners] = useState<Winner[]>([]);
  // const [filteredWinners, setFilteredWinners] = useState<Winner[]>([]);
  const [filterType, setFilterType] = useState<string>("");
  const [filterValue, setFilterValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useAuth();
  const printRef = useRef<HTMLDivElement>(null);
  const scrollTopRefs = useRef<(HTMLDivElement | null)[]>([]);
  const scrollRefs = useRef<(HTMLDivElement | null)[]>([]);

  const handleScroll = throttle(
    (ref: HTMLDivElement | null, linkedRef: HTMLDivElement | null) => {
      if (ref && linkedRef) {
        const scrollPercentage =
          ref.scrollLeft / (ref.scrollWidth - ref.clientWidth);
        linkedRef.scrollLeft =
          scrollPercentage * (linkedRef.scrollWidth - linkedRef.clientWidth);
      }
    },
    50,
    { leading: true, trailing: true }
  );

  useEffect(() => {
    setIsLoading(true);

    http
      .get("/ranking/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const activeRooms = response.data.conteudo || [];

        const apiData = activeRooms.flatMap((room: any) => {
          const rankingInformacoes = room.rankingInformacoes || [];
          return rankingInformacoes.map((info: any) => ({
            salaId: room.salaId || 0,
            rankingId: info.rankingId || 0,
            cotasId: info.cotasId || 0,
            ordem: info.ordem || 0,
            nome: info.nomeUsuario || "",
            cidade: info.cidade || "",
            totalPontos: info.totalPontos || 0,
            statusSala: room.statusSala || { id: 0, descricao: "" },
            cotasConcursosVencedores: info.cotasConcursosVencedores || [],
          }));
        });

        setWinners(apiData);
        // setFilteredWinners(apiData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Erro na requisição:", error);
        setIsLoading(false);
      });
  }, [token]);

  // useEffect(() => {
  //   let filtered = winners;

  //   if (filterType && filterValue) {
  //     filtered = winners.filter((winner) => {
  //       const valueToFilter = (() => {
  //         switch (filterType) {
  //           case "bolao":
  //             return String(winner.salaId);
  //           case "palpite":
  //             return String(winner.cotasId);
  //           case "nome":
  //             return winner.nome.toLowerCase();
  //           case "concurso":
  //             return winner.cotasConcursosVencedores
  //               .map((concurso) => String(concurso.numeroConcurso))
  //               .join(", ");
  //           default:
  //             return "";
  //         }
  //       })();

  //       return valueToFilter.includes(filterValue.toLowerCase());
  //     });
  //   }

  //   setFilteredWinners(filtered);
  // }, [filterType, filterValue, winners]);

  const getStatusText = (status: string) => {
    if (status === "Aberta") {
      return "Vendas Abertas";
    }
    if (status === "Finalizada") {
      return "Finalizado";
    }
    return status;
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "Aberta":
        return "#068C1C";
      case "Em Andamento":
        return "#FFDF01";
      case "Finalizada":
        return "#DB0101";
      default:
        return "gray";
    }
  };

  const getStatus = (status: string) => {
    switch (status) {
      case "Aberta":
        return "white";
      case "Em Andamento":
        return "#068D16";
      case "Finalizada":
        return "white";
      default:
        return "black";
    }
  };

  const formatNumbers = ({ numerosCotas }: { numerosCotas: NumeroCota[] }) => {
    if (!numerosCotas) {
      return null;
    }

    return (
      <>
        {numerosCotas.map((numObj, index) => (
          <Box
            key={index}
            bg={numObj.numeroAcertado ? "green" : "white"}
            color={numObj.numeroAcertado ? "white" : "green"}
            border="2px solid green"
            borderRadius="50%"
            width="26px"
            height="26px"
            lineHeight="26px"
            marginRight="5px"
            display="inline-flex"
            justifyContent="center"
            alignItems="center"
          >
            {numObj.numero}
          </Box>
        ))}
      </>
    );
  };

  const applyFilter = () => {
    if (filterType !== "concurso") {
      return winners.filter((winner) => {
        const valueToFilter = (() => {
          switch (filterType) {
            case "bolao":
              return String(winner.salaId);
            case "palpite":
              return String(winner.cotasId);
            case "nome":
              return winner.nome.toLowerCase();
            default:
              return "";
          }
        })();

        return valueToFilter.includes(filterValue.toLowerCase());
      });
    }

    const result = winners
      .filter((winner) => {
        return winner.cotasConcursosVencedores
          .map((concurso) => String(concurso.numeroConcurso))
          .join(", ")
          .includes(filterValue.toLowerCase());
      })
      .map((winner) => {
        return {
          ...winner,
          cotasConcursosVencedores: winner.cotasConcursosVencedores.filter(
            (value) => String(value.numeroConcurso).includes(filterValue)
          ),
        };
      });

    if (result[0].cotasConcursosVencedores.length < 2) {
      let currentRank = 0;
      let lastValue: number | null = null;
      return result
        .sort(
          (a, b) =>
            b.cotasConcursosVencedores[0].totalPontosConcurso -
            a.cotasConcursosVencedores[0].totalPontosConcurso
        )
        .map((winner) => {
          if (
            lastValue !== winner.cotasConcursosVencedores[0].totalPontosConcurso
          ) {
            currentRank += 1;
            lastValue = winner.cotasConcursosVencedores[0].totalPontosConcurso;
          }
          return {
            ...winner,
            ordem: currentRank,
          };
        });
    }
    return result;
  };

  const filteredWinners = applyFilter();

  const winnersToShow = filterValue.length > 0 ? filteredWinners : winners;

  const groupedWinners = winnersToShow.reduce(
    (acc: { [key: number]: Winner[] }, winner) => {
      if (!acc[winner.salaId]) {
        acc[winner.salaId] = [];
      }
      acc[winner.salaId].push(winner);
      return acc;
    },
    {}
  );

  return isLoading ? (
    <Flex flex="1" alignItems="center" justifyContent="center">
      <CircularProgress color="yellow" isIndeterminate />
    </Flex>
  ) : (
    <Container maxWidth={["100vw", "80vw"]} p="4" mx="auto">
      <Stack spacing={4}>
        <Heading textAlign="center" color="white" fontWeight="bold">
          Ranking
        </Heading>
        <Text
          fontSize="18px"
          textAlign="center"
          color="white"
          fontWeight="bold"
        >
          Confira abaixo o ranking dos palpites
        </Text>

        <Center>
          <Stack direction="row" spacing={4} alignItems="center">
            <Select
              bg="white"
              value={filterType}
              onChange={(e) => {
                setFilterType(e.target.value);
                setFilterValue("");
              }}
              maxW="200px"
            >
              <option value="">Selecione o Filtro</option>
              <option value="bolao">Nº do Bolão</option>
              <option value="palpite">Nº do Palpite</option>
              <option value="concurso">Nº do Concurso</option>{" "}
              <option value="nome">Nome</option>
            </Select>

            {filterType && (
              <Input
                bg="white"
                placeholder={`Buscar por ${filterType}`}
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
                maxW="200px"
              />
            )}
          </Stack>
        </Center>
        <div ref={printRef}>
          {Object.entries(groupedWinners).length > 0 ? (
            Object.entries(groupedWinners).map(
              ([salaId, salaWinners], index) => (
                <Box
                  key={salaId}
                  bg="white"
                  borderRadius="5px"
                  overflow="hidden"
                  mb={4}
                >
                  <Box bg="#006391" py={2}>
                    <Text color="white" textAlign="center" fontWeight="bold">
                      Bolão {String(salaId).padStart(4, "0")}
                    </Text>
                  </Box>
                  <Box textAlign="center" my="3">
                    <Box
                      bg={getStatusColor(salaWinners[0].statusSala.descricao)}
                      color={getStatus(salaWinners[0].statusSala.descricao)}
                      fontWeight="bold"
                      mx="auto"
                      pr={1}
                      pl={1}
                      width="fit-content"
                    >
                      {getStatusText(salaWinners[0].statusSala.descricao)}{" "}
                    </Box>
                  </Box>

                  <Box overflowX="auto">
                    <Box
                      ref={(el) => (scrollTopRefs.current[index] = el!)}
                      height="20px"
                      overflowX="scroll"
                      whiteSpace="nowrap"
                      mb={2}
                      onScroll={() =>
                        handleScroll(
                          scrollTopRefs.current[index],
                          scrollRefs.current[index]
                        )
                      }
                    >
                      <Box width="2000px" />
                    </Box>
                    <Box
                      ref={(el) => (scrollRefs.current[index] = el!)}
                      overflowX="scroll"
                      onScroll={() =>
                        handleScroll(
                          scrollRefs.current[index],
                          scrollTopRefs.current[index]
                        )
                      }
                    >
                      {" "}
                      <Table variant="simple" maxW="100%" whiteSpace="nowrap">
                        <Thead>
                          <Tr bg="#006391" color="white">
                            <Th color="white" textAlign="center">
                              Ranking
                            </Th>
                            <Th color="white" textAlign="center">
                              Número do Palpite
                            </Th>
                            <Th color="white" textAlign="center">
                              Nome
                            </Th>
                            <Th color="white" textAlign="center">
                              Cidade
                            </Th>
                            <Th color="white" textAlign="center">
                              Dia / Número Concurso
                            </Th>
                            <Th color="white" textAlign="center">
                              Palpites
                            </Th>
                            <Th color="white" textAlign="center">
                              Pontuação por Concurso
                            </Th>
                            {salaWinners[0].cotasConcursosVencedores.length <
                            2 ? null : (
                              <>
                                <Th color="white" textAlign="center">
                                  Total de Pontos
                                </Th>{" "}
                              </>
                            )}
                          </Tr>
                        </Thead>
                        <Tbody bg="#DBEFFF">
                          {salaWinners.map((winner, index) => (
                            <Tr
                              key={winner.cotasId}
                              borderBottom="10px solid #fff"
                              borderTop="10px solid #fff"
                            >
                              <Td
                                textAlign="center"
                                color="#006391"
                                fontWeight="bold"
                              >
                                {winner.ordem !== null &&
                                winner.ordem !== undefined
                                  ? String(winner.ordem).padStart(4, "0")
                                  : "N/A"}
                              </Td>
                              <Td
                                textAlign="center"
                                color="#006391"
                                fontWeight="bold"
                              >
                                {String(winner.cotasId).padStart(4, "0")}
                              </Td>
                              <Td
                                textAlign="center"
                                color="#006391"
                                fontWeight="bold"
                              >
                                {winner.nome}
                              </Td>
                              <Td
                                textAlign="center"
                                color="#006391"
                                fontWeight="bold"
                              >
                                {winner.cidade}
                              </Td>
                              <Td
                                textAlign="center"
                                color="#006391"
                                fontWeight="bold"
                              >
                                {winner.cotasConcursosVencedores.map(
                                  (detalhe, idx) => (
                                    <Box key={detalhe.numeroConcurso} mb={2}>
                                      {detalhe.diaConcurso}{" "}
                                      <strong style={{ color: "black" }}>
                                        {detalhe.numeroConcurso}
                                      </strong>
                                    </Box>
                                  )
                                )}
                              </Td>
                              <Td
                                textAlign="center"
                                color="#006391"
                                fontWeight="bold"
                              >
                                {winner.cotasConcursosVencedores.map(
                                  (detalhe, idx) => (
                                    <Box key={idx} mb={2}>
                                      {formatNumbers({
                                        numerosCotas: detalhe.numerosCotas,
                                      })}
                                    </Box>
                                  )
                                )}
                              </Td>

                              <Td
                                textAlign="center"
                                color="#006391"
                                fontWeight="bold"
                              >
                                {winner.cotasConcursosVencedores.map(
                                  (detalhe, idx) => (
                                    <Box key={idx} mb={2}>
                                      {detalhe.totalPontosConcurso}
                                    </Box>
                                  )
                                )}
                              </Td>
                              {salaWinners[0].cotasConcursosVencedores.length <
                              2 ? null : (
                                <Td
                                  textAlign="center"
                                  bg="#006391"
                                  color="white"
                                  fontWeight="bold"
                                >
                                  {winner.totalPontos}
                                </Td>
                              )}
                            </Tr>
                          ))}
                        </Tbody>{" "}
                      </Table>
                    </Box>
                  </Box>
                </Box>
              )
            )
          ) : (
            <Box
              bg="white"
              borderRadius={5}
              mx="auto"
              width={{ base: "90%", md: "40%", lg: "30%" }}
            >
              <Text fontWeight="bold" p={4} textAlign="center">
                Sem dados disponíveis.
              </Text>
            </Box>
          )}{" "}
        </div>
      </Stack>
      <FloatingWhatsAppButton />
    </Container>
  );
};

export default Ranking;
