import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Stack,
  IconButton,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useState, useEffect } from "react";
import { useAuth } from "../../contexts/authContext";
import { http } from "../../services/http";
import { useLocation } from "react-router-dom";

type Compra = {
  data: string;
  palpite: string;
  numeros: string;
};

type GrupoCompra = {
  data: string;
  valorCompra: number;
  compras: Compra[];
};

type User = {
  nome: string;
  cidade: string;
  telefone: string;
};

type FormatNumbersProps = {
  numbers: string;
  correctNumbers: string;
};

const formatNumbers = ({ numbers, correctNumbers }: FormatNumbersProps) => {
  if (!numbers || !correctNumbers) {
    return null;
  }

  const numbersArray = numbers.split(" ");

  return (
    <Box display="flex" whiteSpace="nowrap" overflowX="auto">
      {numbersArray.map((number: string, index: number) => (
        <Box
          key={index}
          bg="transparent"
          color="#098D1E"
          fontWeight="bold"
          border="2px solid green"
          borderRadius="50%"
          width="26px"
          height="26px"
          lineHeight="26px"
          marginRight="5px"
          display="inline-flex"
          justifyContent="center"
          alignItems="center"
        >
          {number}
        </Box>
      ))}
    </Box>
  );
};

const HistoricoCompras = () => {
  const location = useLocation();
  const { userId } = location.state;
  const [user, setUser] = useState<User | null>(null);
  const { token } = useAuth();
  const [gruposCompras, setGruposCompras] = useState<GrupoCompra[]>([]);

  const formatDate = (dateString: string) => {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };

  const formatNumber = (num: string) => {
    const number = parseInt(num, 10);
    return number >= 100 ? num : number < 10 ? `000${number}` : `00${number}`;
  };

  useEffect(() => {
    const fetchHistoricoCompras = async () => {
      try {
        const response = await http.get(`/cotas/historico/usuario/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const userData = {
          nome: response.data.conteudo.nome,
          cidade: response.data.conteudo.cidade || "Cidade não informada",
          telefone: response.data.conteudo.telefone,
        };

        const grupos = response.data.conteudo.historicoSalasCotas.flatMap(
          (sala: any) =>
            sala.dataValorCompra.map((data: any) => ({
              data: data.dataCompra,
              valorCompra: data.valorCompra,
              compras: data.palpitesSala.map((palpite: any) => ({
                data: data.dataCompra,
                palpite: palpite.cotasId.toString(),
                numeros: palpite.numerosCota
                  .map((numeroObj: any) => numeroObj.numero)
                  .join(" "),
              })),
            }))
        );
        setUser(userData);
        setGruposCompras(grupos);
      } catch (error) {
        console.error("Erro ao buscar histórico de compras:", error);
      }
    };

    fetchHistoricoCompras();
  }, [userId, token]);

  return (
    <Stack spacing={4} p="4" mx="auto" maxW="100%">
      <Box bg="white" borderRadius="5px" overflow="hidden" position="relative">
        <IconButton
          icon={<ArrowBackIcon />}
          aria-label="Voltar"
          position="absolute"
          top={2}
          left={2}
          colorScheme="blue"
          onClick={() => window.history.back()}
        />

        <Box pt="8" pb="4" px="4" textAlign="center">
          {user && (
            <Box mb="4">
              <Text fontWeight="bold" fontSize="lg">
                Usuário: {formatNumber(userId)}
              </Text>
              <Text fontWeight="bold">Nome: {user.nome}</Text>
              <Text fontWeight="bold">Cidade: {user.cidade}</Text>
              <Text fontWeight="bold">Telefone: {user.telefone}</Text>
            </Box>
          )}
          <Text mt={5} color="#068C1C" fontSize="2xl" fontWeight="bold" mb={4}>
            Histórico de Compras
          </Text>

          {gruposCompras.length === 0 ? (
            <Text fontSize="lg" color="gray.500">
              Ainda não há compras
            </Text>
          ) : (
            gruposCompras.map((grupo, index) => (
              <Box key={index} mb={4}>
                <Box overflowX="auto" width="full">
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th textAlign="center">Palpite</Th>
                        <Th textAlign="center">Números</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {grupo.compras.map((compra, idx) => (
                        <Tr key={index}>
                          <Td
                            textAlign="center"
                            color="#068C1C"
                            fontWeight="bold"
                          >
                            {compra.palpite}
                          </Td>
                          <Td textAlign="center">
                            {formatNumbers({
                              numbers: compra.numeros,
                              correctNumbers: compra.numeros || "",
                            })}
                          </Td>{" "}
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                  <Box textAlign="center" mt="4">
                    <Text fontWeight="bold">
                      Data da Compra: {formatDate(grupo.data)}
                    </Text>
                  </Box>
                </Box>
                <Text fontWeight="bold" pb={5}>
                  Valor da Compra:{" "}
                  {grupo.valorCompra.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </Text>
              </Box>
            ))
          )}
        </Box>
      </Box>
    </Stack>
  );
};

export default HistoricoCompras;
